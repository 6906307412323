import React from 'react';
import { useSearch } from './SearchContext';

const SearchField = ({ title, placeholder, className }) => {
  const { searchValues, setSearchValues } = useSearch();
  const fieldName = title.replace(/\s+/g, '').toLowerCase();

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [fieldName]: inputValue,
    }));
  };

  return (
    <div className={`mb-4 ${className}`}>
      <input
        type="text"
        value={searchValues[fieldName]}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="w-full p-2 border border-gray-300 rounded"
      />
    </div>
  );
};

export default SearchField;
