import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseUrl from './apiConfig';
import { Helmet } from 'react-helmet';

function Ranking() {
  const [userRankings, setUserRankings] = useState([]);

useEffect(() => {
  axios
    .get(`${baseUrl}/api/user_rankings`)
    .then((response) => setUserRankings(response.data))
    .catch((error) => {
      console.error('Error fetching user rankings:', error);
      console.error('Error response:', error.response);
      console.error('Error request:', error.request);
      alert('Failed to fetch user rankings. Please try again.');
    });
}, []);


  return (
    <div className="container mx-auto">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="HomeAI leader board to evaluate and rank predictions" />
      </Helmet>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Rank
              </th>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {userRankings.map((ranking, index) => (
              <tr
                key={ranking.user_id}
                className={`${
                  index % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700'
                } border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                <td className="px-6 py-4">{ranking.final_rank}</td>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {ranking.username}
                </th>
                <td className="px-6 py-4">{parseFloat(ranking.final_score).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
}

export default Ranking;
