import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../App';
import baseUrl from './apiConfig';
import ApartmentCardSaved from './ApartmentCardSaved';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function TrackingPage() {
  const [apartments, setApartments] = useState([]);
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/user_predictions?userId=${userId}`)
      .then((response) => setApartments(response.data))
      .catch((error) => console.error(error));
      
  }, [userId]);

  function formatNumberWithWhitespace(number) {
    if (number === null || number === undefined) {
      return null;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  apartments.sort((a, b) => {
    if (a.apartment_rank === null) {
      return 1;
    }
    if (b.apartment_rank === null) {
      return -1;
    }
    return a.apartment_rank - b.apartment_rank;
  });

  apartments.flat().map((apartment) => {
    apartment.list_price       = formatNumberWithWhitespace(apartment.list_price);
    apartment.prediction       = formatNumberWithWhitespace(apartment.prediction);
    apartment.sold_price       = formatNumberWithWhitespace(apartment.sold_price);
    apartment.user_prediction  = formatNumberWithWhitespace(apartment.user_prediction);
    apartment.rent             = formatNumberWithWhitespace(apartment.rent);
    return apartment;
  });

  if (apartments.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-md overflow-hidden w-full sm:w-100 p-6 border-solid border-2 flex flex-col justify-center items-center">
        <Helmet>
          <title>HomeAI</title>
          <meta name="description" content="Track your saved apartments, follow up on your predictions and recvice rewards" />
        </Helmet>
        <h2 className="text-2xl font-semibold mb-4">No Tracked Apartments</h2>
        <p className="mb-4">
          Make a prediction to add the apartment to the tracking list.
        </p>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/find')}
        >
          Find
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 2xl:px-14">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="Track your saved apartments, follow up on your predictions and recvice rewards" />
      </Helmet>
      {apartments.flat().map((apartment, index) => (
        <ApartmentCardSaved key={index} apartment={apartment} />
            ))}
      <div className="bg-white rounded-xl shadow-md overflow-hidden w-full sm:w-100 p-6 border-solid border-2 flex flex-col justify-center items-center">
        <p className="mb-4">
          Make a prediction to add the apartment to the tracking list.
        </p>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/find')}
        >
          Find
        </button>
      </div>
    </div>
  );
}

export default TrackingPage;
