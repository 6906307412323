import React from 'react';
import NavigationDots from './NavigationDots';
import { FaArrowLeft } from 'react-icons/fa';
import SkipButton from './SkipButton';

function Balcony({ setFormData, formData, nextStep, prevStep }) {
    const handleBalconyChange = (value) => {
        setFormData({
            ...formData,
            balcony: value
        });
        nextStep();
    };
    
    return (
        <>
            <div className="flex flex-col items-center mt-6 md:mt-0 w-4/5 sm:w-full mx-auto max-w-2xl md:justify-start">
                <div className="absolute left-0 text-xl ml-2" onClick={prevStep}>
                    <FaArrowLeft />
                </div>
                <NavigationDots blackIndex={5} />
            
                <div className='mt-4 font-semibold w-4/5'>Finns det balkong?</div>
                <div className="flex justify-center mt-4 w-full">
                    <button 
                        className="bg-black text-white rounded-full w-2/5  mr-1 h-11 font-semibold"
                        onClick={() => handleBalconyChange(true)}>
                        Ja
                    </button>
                    <button 
                        className="bg-black text-white rounded-full w-2/5 ml-1 h-11 font-semibold" 
                        onClick={() => handleBalconyChange(false)}>
                        Nej
                    </button>
                </div>
                <div className="flex justify-center mt-6 w-full">
                    <SkipButton nextStep={nextStep} />
                </div>
            </div>
        </>
    );
}

export default Balcony;