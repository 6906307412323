import React, { useContext, useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import Axios from 'axios';
import { UserContext } from '../App';
import ApartmentCard from './ApartmentCard';
import StickySidebar from './StickySidebar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearch } from './SearchContext';
import baseUrl from './apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';


function Find() {
  const [searchClicked, setSearchClicked] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { userId } = useContext(UserContext);
  const { search } = useSearch();
  const { searchValues } = useSearch();


  const handleSearchClick = () => {
    setSearchClicked(prevState => !prevState);
  };

  const handleClick = () => {
    setShowSidebar(!showSidebar);
  };
  
  const { data, fetchNextPage, hasNextPage, isLoading, isError, remove, refetch } = useInfiniteQuery(
    ['find', search],
    async ({ pageParam = 0 }) => {
      const response = await Axios.get(`${baseUrl}/api/apartments`, {
        params: {
          userId: userId,
          page: pageParam,
          search: search,
          area: searchValues.area,
          address: searchValues.address,
          minsqm: searchValues.minsqm,
          maxsqm: searchValues.maxsqm,
          minrooms: searchValues.minrooms,
          maxrooms: searchValues.maxrooms,
          minbuilt: searchValues.minbuilt,
          maxbuilt: searchValues.maxbuilt,
        },
      });
      return response.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 0) return false;
        return allPages.length;
      },
      onSuccess: () => setSearchClicked(false),
      refetchOnWindowFocus: false,
    }
  );
  
  useEffect(() => {
    if (searchClicked) {
      remove();
    }
  }, [searchClicked, remove]);

  useEffect(() => {
    if (searchClicked) {
      refetch();
    }
  }, [searchClicked, refetch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="flex">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="Find the most profitable apartment deal" />
      </Helmet>
      <div className={`sticky top-0 h-screen p-4 bg-gray-100 border-r border-gray-200 w-64 space-y-4 ${showSidebar ? '' : 'hidden'}`}>
        <StickySidebar onSearchClick={handleSearchClick} />
      </div>
      <button
        onClick={handleClick}
        className={`fixed top-16 left-4 w-10 text-white rounded-lg z-50 ${showSidebar ? 'bg-gray-100' : ''}`}
      >
        <FontAwesomeIcon icon={faSearch} className="text-blue-600 border-solid border-2 p-1 border-blue-600 rounded-md" />
      </button>
      <div className="flex flex-col items-center flex-grow">
        <InfiniteScroll
          dataLength={data.pages.flat().length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<div>Loading...</div>}
          endMessage={<div>No more apartments to load</div>}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {data.pages.flat().map((apartment, index) => (
              <ApartmentCard key={index} apartment={apartment} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Find;
