import axios from 'axios';
import apiBaseUrl from './apiConfig';

const formatData = (data) => {
    // Set to null if empty
    return {
        rent: parseInt(data.rent),
        livingArea: parseFloat(data.livingArea),
        rooms: parseFloat(data.rooms),
        floor: parseFloat(data.floor),
        constructionYear: parseInt(data.constructionYear),
        streetAddress: data.streetAddress,
        postcode: data.postcode,
        balcony: data.balcony === "true"
    };
};

const getPrediction = async (formData) => {
    formData = formatData(formData);
    console.log(formData);
    try {
        const response = await axios.post(`${apiBaseUrl}/predict`, formData);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;  
    }
};


export default getPrediction;