import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import baseUrl from './apiConfig';
import { UserContext } from '../App';
import { Helmet } from 'react-helmet';

 
function Login() {
  const { handleLogin } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const loginUser = async () => {
    const data = {
      username: username,
      password: password,
    };

    try {
      const res = await axios.post(`${baseUrl}/api/login`, data);

      if (res.data.message !== "Invalid username or password.") {
        setUsername(data.username);
        handleLogin({ username: data.username, id: res.data.userId });
        navigate('/home'); 
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="Login to your HomeAI account" />
      </Helmet>
      <div className="bg-white w-full max-w-md rounded-lg shadow p-8">
        <h1 className="text-2xl font-bold mb-8 text-blue-600">Login</h1>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 mb-6 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <button
          onClick={loginUser}
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
