import { useState } from 'react';
import axios from 'axios';
import baseUrl from './apiConfig';

const UserPrediction = (userId, booliId, initialUserPrediction) => {
  const [userPrediction, setUserPrediction] = useState(initialUserPrediction);
  const [successMessage, setSuccessMessage] = useState(null);

  const toInt = (value) => {
    return parseInt(value.replace(/\s+/g, ''), 10);
  };

  const submitPrediction = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/add_user_prediction`, {
        userId: userId,
        booliId: booliId,
        prediction: toInt(userPrediction)
      });

      console.log(response.data);
      setSuccessMessage('Prediction added successfully!');
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error submitting prediction:', error);
    }
  };

  return [userPrediction, setUserPrediction, successMessage, submitPrediction];
};

export default UserPrediction;
