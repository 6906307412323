import React from 'react';

const SkipButton = ({ nextStep }) => {
  return (
    <button 
      className="text-black border-2 border-black rounded-full p-2 w-2/5 h-11 font-bold" 
      onClick={nextStep}>
      Hoppa över
    </button>
  );
};

export default SkipButton;