import React, { useContext } from 'react';
import { UserContext } from '../App';

function Landing() {

  const { loggedIn } = useContext(UserContext);
  
  return (
    <div>
      <header className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-center py-16">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">HOMEAI</h1>
        <p className="text-lg md:text-xl mb-8">We help you make the most important decision in your life.</p>
        <a href={loggedIn ? "/home" : "/register"} className="bg-white text-blue-600 py-2 px-6 rounded-md font-semibold">Get Started</a>
      </header>
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">Our Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="rounded-md bg-gray-100 p-8">
              <h3 className="text-xl font-bold mb-4">Accurate Price Predictions</h3>
              <p className="text-lg mb-4">Our service is designed to help people make informed decisions about buying and selling property. With our price predictions, users can quickly and easily assess the value of an apartment and make informed decisions about whether to buy or sell. This can lead to faster sales, better returns on investment, and improved financial performance for everyone involved.</p>
            </div>

            <div className="rounded-md bg-gray-100 p-8">
              <h3 className="text-xl font-bold mb-4">For Everyone Involved</h3>
              <p className="text-lg mb-4">Whether you're a real estate agent, property investor, bank, mortgage lender, or homeowner, our service has something to offer. Our accurate price predictions can help you make better decisions and achieve your goals faster.</p>
            </div>

            <div className="rounded-md bg-gray-100 p-8">
              <h3 className="text-xl font-bold mb-4">Easy to Use</h3>
              <p className="text-lg mb-4">We understand that buying and selling property can be a complex and stressful process. That's why we've made our service easy to use and accessible to everyone. With just a few clicks, you can get a price prediction for any apartment on the market.</p>
            </div>
          </div>
        </div>
    </section>
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="rounded-md bg-white p-8">
            <h3 className="text-xl font-bold mb-4">Machine Learning</h3>
            <p className="text-lg mb-4">Machine learning is a subset of artificial intelligence (AI) that involves the development of algorithms that can learn and make predictions based on data. In the context of real estate, machine learning can be used to analyze large datasets of property sales and identify patterns and trends that can be used to make accurate predictions about the value of a property.</p>
          </div>
          <div className="rounded-md bg-white p-8">
            <h3 className="text-xl font-bold mb-4">Model Training</h3>
            <p className="text-lg mb-4">To train our machine learning model, we start by gathering a large dataset of property sales data. This dataset typically includes information about the size of the property, its location, the number of bedrooms and bathrooms, and other relevant features that can influence its value. We then use this data to train our model to recognize patterns and make predictions based on these patterns.</p>
          </div>
          <div className="rounded-md bg-white p-8">
            <h3 className="text-xl font-bold mb-4">Validation</h3>
            <p className="text-lg mb-4">We use a technique called "validation" to ensure that our machine learning model is accurate and reliable. This involves testing the model on a separate dataset of properties that it has not been trained on, and comparing its predictions with the actual sales prices of these properties. By doing this, we can ensure that our model is accurate and reliable, and that it can be used with confidence by our users.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  );
}

export default Landing;