import { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const [searchValues, setSearchValues] = useState({
    area: '',
    address: '',
    minsqm: '',
    maxsqm: '',
    minrooms: '',
    maxrooms: '',
    minbuilt: '',
    maxbuilt: '',
  });

  return (
    <SearchContext.Provider value={{ searchValues, setSearchValues }}>
      {children}
    </SearchContext.Provider>
  );
};


export default SearchContext;