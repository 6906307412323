import React from 'react';

const NextButton = ({ nextStep, isClickable }) => {
  return (
    <button 
      className={`rounded-full p-2 w-2/5 mr-6 h-11 font-semibold ${isClickable ? 'bg-black text-white' : 'bg-gray-400 text-gray-700 pointer-events-none'}`} 
      onClick={isClickable ? nextStep : null}
    >
      Nästa
    </button>
  );
};

export default NextButton;