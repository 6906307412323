import React, { useState } from 'react';
import axios from 'axios';
import apiBaseUrl from './apiConfig';

function AddressInput({ onAddressSelect }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.length > 2) {
      try {
        const response = await axios.post(`${apiBaseUrl}/location_search`, {
          pattern: value
        });
        setSuggestions(response.data.results);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.display_name_short);
    onAddressSelect(suggestion);
    setSuggestions([]);  // clear suggestions after selecting
  };

  return (
    <div className="relative flex justify-center mt-6 w-full">
      <input
        type="text"
        className="border rounded-lg p-2 w-full"
        placeholder="Address"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 200)}  // delay to allow suggestion click
      />
      {isFocused && suggestions.length > 0 && (
        <ul className="absolute top-full mt-2 w-full border border-gray-300 rounded-lg bg-white z-10">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.display_name_short}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AddressInput;
