import React from 'react';

const NavigationDots = ({ blackIndex, totalDots = 7 }) => {
    return (
        <div className='flex'>
            {Array.from({ length: totalDots }, (_, index) => (
                <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${index !== totalDots - 1 ? 'mr-3' : ''} ${index === blackIndex ? 'bg-black' : 'bg-gray-300'}`}
                ></div>
            ))}
        </div>
    );
};

export default NavigationDots;
