import React, { useState, useEffect, useRef } from 'react';
import NavigationDots from './NavigationDots';
import { FaArrowLeft } from 'react-icons/fa';
import NextButton from './NextButton';

function Sqm({ setFormData, formData, nextStep, prevStep }) {
    const [inputValid, setInputValid] = useState(true);
    const [buttonValid, setButtonValid] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const value = formData.livingArea;
        if (value >= 15 && value <= 500) {
            setButtonValid(true);
        } else {
            setButtonValid(false);
        }
    }, [formData.livingArea]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && buttonValid) {
            nextStep();
        }
    };

    const handleSqmChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            setInputValid(true);
            setFormData({
                ...formData,
                livingArea: value
            });
        } else {
            setInputValid(false);
        }
    };
    
    return (
        <>
            <div className="flex flex-col items-center mt-6 md:mt-0 w-4/5 sm:w-full mx-auto max-w-2xl md:justify-start">
                <div className="absolute left-0 text-xl ml-2" onClick={prevStep}>
                    <FaArrowLeft />
                </div>
                <NavigationDots blackIndex={0} />

                <div className="flex justify-center mt-6 w-full">
                    <input
                        ref={inputRef}
                        type="text" 
                        className="border rounded-lg p-2 w-full" 
                        placeholder="Hur många kvadratmeter? (15-500㎡)" 
                        value={formData.livingArea || ''} 
                        onChange={handleSqmChange}
                        onKeyDown={handleKeyDown}
                        pattern="\d*"/>
                </div>
                {!inputValid && <div className="text-red-500 text-sm mt-2">Invalid input. Please enter a number.</div>}
                <div className="flex justify-center mt-6 w-full">
                    <NextButton nextStep={nextStep} isClickable={buttonValid}/>
                </div>
            </div>
        </>
    );
}

export default Sqm;
