import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import baseUrl from './apiConfig';
import { UserContext } from '../App';
import { Helmet } from 'react-helmet';



function Register() {
  const { handleLogin } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

 
  const register = async () => {
    if (!validateInput()) {
      return;
    }
    const res = await axios.post(`${baseUrl}/api/register`, { username, password, email });
    if (res.data.message === "User registered successfully!") {
      alert(res.data.message);
      console.log(res.data);
      handleLogin({ username: username, id: res.data.userId });
      navigate('/home');
    } else {
      alert(res.data.message);
    }
  };

  const validateInput = () => {
    if (password.length < 6) {
      alert("Password should be at least 6 characters long.");
      return false;
    }
    if (password !== repeatPassword) {
      alert("Passwords do not match.");
      return false;
    }
    if (!email.includes('@') || !email.includes('.')) {
      alert("Invalid email format.");
      return false;
    }
    return true;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="Create a new HomeAI account" />
      </Helmet>
      <div className="bg-white w-full max-w-md rounded-lg shadow p-8">
        <h1 className="text-2xl font-bold mb-8 text-blue-600">Create Your Account</h1>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <input
          type="password"
          placeholder="Repeat Password"
          onChange={(e) => setRepeatPassword(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mb-6 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <button
          onClick={register}
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
        >
          Register
        </button>
      </div>
    </div>
  );
}

export default Register;
