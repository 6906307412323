
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../App';

function Navbar() {
  const { loggedIn } = useContext(UserContext);

  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="text-white font-bold text-lg">
            <NavLink to="/landing">HOMEAI</NavLink>
          </div>
          <div className="flex items-center">
            {loggedIn ? (
              <>
                <NavLink
                  to="/home"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/find"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Find
                </NavLink>
                <NavLink
                  to="/ranking"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Ranking
                </NavLink>
                <NavLink
                  to="/tracking"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Tracking
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  to="/register"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Register
                </NavLink>
                <NavLink
                  to="/login"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Login
                </NavLink>
                <NavLink
                  to="/find"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Find
                </NavLink>
                <NavLink
                  to="/ranking"
                  className="text-white mx-2 hover:text-blue-100"
                >
                  Ranking
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
