import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { Helmet } from 'react-helmet';


function Home({ onLogout }) {
  const { username } = useContext(UserContext);
  const navigate = useNavigate();

  const logoutUser = () => {
    onLogout();
    navigate('/landing');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="Manage your HomeAI account" />
      </Helmet>
      <div className="bg-white w-full max-w-md rounded-lg shadow p-8">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">Welcome, {username}!</h2>
        <p className="mb-6">You are now logged in. Welcome to the home page!</p>
        <button
          onClick={logoutUser}
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Home;
