import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faMedal, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../App';
import UserPrediction from './UserPrediction';



const ExpandableComponent = ({ apartment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { userId } = useContext(UserContext);
  const [userPrediction, setUserPrediction, successMessage, submitPrediction] = UserPrediction(userId, apartment.booliId, "");
  

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setUserPrediction(apartment.user_prediction);
  }, [apartment.user_prediction, setUserPrediction]);

  const getRankColorClass = (rank) => {
    if (rank === 1) return 'text-gold';
    if (rank === 2) return 'text-silver';
    if (rank === 3) return 'text-bronze';
    if (rank >= 4 && rank <= 10) return 'text-green-600';
    return '';
  };
  
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden w-full sm:w-100 p-4 border-solid border-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-xl font-semibold mb-2 mr-2">{apartment.address}</div>
          <a href={`https://www.booli.se/annons/${apartment.booliId}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
        <div className="flex items-center">
          {(apartment.apartment_rank !== null && apartment.apartment_rank <= 10) && (
            <FontAwesomeIcon icon={faMedal} className={`${getRankColorClass(apartment.apartment_rank)} mr-2`} size="2x" />
          )}
          <button
            className="focus:outline-none"
            onClick={handleExpandClick}
          >
            {isExpanded ? (
              <FontAwesomeIcon icon={faMinus} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faPlus} size="2x" />
            )}
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <p className="text-sm font-semibold">Area</p>
            <p>{apartment.area}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">m&sup2;</p>
            <p>{apartment.sqm}</p>
          </div>
          {apartment.built && (
            <div>
              <p className="text-sm font-semibold">Built</p>
              <p>{apartment.built}</p>
            </div>
          )}
          {apartment.floor && (
            <div>
              <p className="text-sm font-semibold">Floor</p>
              <p>{apartment.floor}</p>
            </div>
          )}
          {apartment.rooms && (
            <div>
              <p className="text-sm font-semibold">Rooms</p>
              <p>{apartment.rooms}</p>
            </div>
          )}
          {apartment.days_for_sale && (
            <div>
              <p className="text-sm font-semibold">Days for Sale</p>
              <p>{apartment.days_for_sale}</p>
            </div>
          )}
          {apartment.rent && (
            <div>
              <p className="text-sm font-semibold">Rent</p>
              <p>{apartment.rent}</p>
            </div>
          )}
          <div>
            <p className="text-sm font-semibold">Status</p>
            <p>{apartment.status}</p>
            </div>
          {apartment.list_price && (
            <div>
              <p className="text-sm font-semibold">List Price</p>
              <p>{apartment.list_price} Kr</p>
            </div>
          )}
          {apartment.prediction && (
            <div>
              <p className="text-sm font-semibold">HomeAI Prediction</p>
              <p>{apartment.prediction } Kr</p>
            </div>
          )} 
          {apartment.sold_price && (
            <div>
              <p className="text-sm font-semibold">Sold Price</p>
              <p>{apartment.sold_price} Kr</p>
            </div>
          )}      
          {apartment.status === 'for sale' ? (
            <div >
              <p className="text-sm font-semibold">Your Prediction</p>
              {successMessage && (
                <div className="bg-green-100 text-green-700 p-2 rounded mb-2">
                  {successMessage}
                </div>
              )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitPrediction();
              }}
              className="flex" 
            >
              <input
                className="border border-gray-300 rounded-md px-2 w-full"
                type="text"
                value={userPrediction}
                onChange={(e) => setUserPrediction(e.target.value)}
                style={{ flexGrow: 1 }} 
              />
              <button
                className="bg-blue-500 text-white rounded-md px-2"
                type="submit"
              >
                Update
              </button>
            </form>
          </div>
          ) : (
          <div>
            <p className="text-sm font-semibold">Your Prediction</p>
            <p>{apartment.user_prediction}</p>
          </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandableComponent;
