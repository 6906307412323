import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationDots from './NavigationDots';
import { FaArrowLeft } from 'react-icons/fa';
import '../styles.css'
import getPrediction from './getPrediction';
import apiBaseUrl from './apiConfig';

function PersonDetails({formData, nextStep, prevStep, setPrediction}) {
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        selectedInterest: '',  
        acceptTerms: false
    });

    useEffect(() => { 
    }, [inputData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData({
            ...inputData,
            [name]: value
        });
    };

    const handleRadioButtonChange = (e) => {
        const { value } = e.target;
        setInputData({
            ...inputData,
            selectedInterest: value
        });
    }

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        setInputData({
            ...inputData,
            acceptTerms: checked
        });
    }

    const handleFormSubmission = async () => {
        const { acceptTerms, ...dataToSend } = inputData;
        const apiEndpoint = `${apiBaseUrl}/customers`;
    
        try {
            await axios.post(apiEndpoint, dataToSend);    
            const prediction = await getPrediction(formData);
            setPrediction(prediction);
        } catch (error) {
            console.error('Error:', error);
        }
        nextStep();

    };


    return (
        <>
            <div className="flex flex-col items-center mt-6 md:mt-0 w-4/5 sm:w-full mx-auto max-w-2xl md:justify-start">
                <div className="absolute left-0 text-xl ml-2" onClick={prevStep}>
                    <FaArrowLeft />
                </div>
                <NavigationDots blackIndex={6} />
            
                <div className="flex flex-col items-center mt-6 w-4/5 mx-auto">
                    <div className='w-full mb-4'>
                        <input 
                            type="text" 
                            name="name"
                            className="border rounded-lg p-2 w-full"
                            placeholder="Namn"
                            value={inputData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='w-full mb-4'>
                        <input 
                            type="text" 
                            name="email"
                            className="border rounded-lg p-2 w-full"
                            placeholder="E-post"
                            value={inputData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='w-full'>
                        <input 
                            type="text" 
                            name="phoneNumber"
                            className="border rounded-lg p-2 w-full"
                            placeholder="Telefonnummer"
                            value={inputData.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='flex justify-left w-full mt-6'>
                        Jag är intressead av att:
                    </div>
                        <div className="flex flex-col justify-left w-full mt-4">
                            <label>
                                <input
                                    type="radio"
                                    name="interest"
                                    value="sell"
                                    checked={inputData.selectedInterest === 'sell'}
                                    onChange={handleRadioButtonChange}
                                />
                                <span className="ml-2">Sälja bostad</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="interest"
                                    value="buy"
                                    checked={inputData.selectedInterest === 'buy'}
                                    onChange={handleRadioButtonChange}
                                />
                                <span className="ml-2">Köpa bostad</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="interest"
                                    value="indication"
                                    checked={inputData.selectedInterest === 'indication'}
                                    onChange={handleRadioButtonChange}
                                />
                                <span className="ml-2">Bara få en värdeindekation</span>
                            </label>
                            <label className='mt-4 flex items-center'>
                                <input 
                                    type="checkbox" 
                                    name="acceptTerms" 
                                    className="round-checkbox" 
                                    checked={inputData.acceptTerms}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="ml-2">Jag godkänner användarvillkoren</span>
                            </label>
                        </div>
                    <div className='w-full mt-6'>
                        <button 
                            className="bg-black text-white rounded-full w-full h-11 font-semibold"
                            onClick={handleFormSubmission}
                            disabled={!inputData.acceptTerms}
                        >    
                            Värdera
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonDetails;