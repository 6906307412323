import React from 'react';
import SearchField from './SearchField';

const StickySidebar = ({ onSearchClick }) => {
  const handleSearchClick = () => {
    onSearchClick();
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-2 p-5" >
      <button
        onClick={handleSearchClick}
        className="bg-blue-600 text-white font-bold py-1 px-4 rounded"
      >
        Find
      </button>
      </div>
      <SearchField title="Area" placeholder="Search by area..." />
      <SearchField title="Address" placeholder="Search by address..." />
      
      <div className="my-4">
        <h2 className="text-center mb-2 text-l font-semibold text-blue-600">m²</h2>
        <div className="flex">
          <SearchField title="Min Sqm" placeholder="Min" className="mr-2" />
          <SearchField title="Max Sqm" placeholder="Max" />
        </div>
      </div>
      
      <div className="my-4">
        <h2 className="text-center mb-2 text-l font-semibold text-blue-600">Rooms</h2>
        <div className="flex">
          <SearchField title="Min Rooms" placeholder="Min" className="mr-2" />
          <SearchField title="Max Rooms" placeholder="Max" />
        </div>
      </div>
      
      <div className="my-4">
        <h2 className="text-center mb-2 text-l font-semibold text-blue-600">Built</h2>
        <div className="flex">
          <SearchField title="Min Built" placeholder="Min" className="mr-2" />
          <SearchField title="Max Built" placeholder="Max" />
        </div>
      </div>
    </div>
  );
};

export default StickySidebar;
