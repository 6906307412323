import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../App';
import UserPrediction from './UserPrediction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const ApartmentCard = ({ apartment }) => {
  const { loggedIn, userId } = useContext(UserContext);
  const [placeholder, setPlaceholder] = useState('Enter your prediction');
  const [userPrediction, setUserPrediction, successMessage, submitPrediction] = UserPrediction(userId, apartment.booliId, "");

  useEffect(() => {
    setUserPrediction('');
    setPlaceholder(apartment.user_prediction ? apartment.user_prediction : 'Enter your prediction');
  }, [apartment.booliId, apartment.user_prediction, setUserPrediction]);

  
  function formatNumberWithWhitespace(number) {
    if (number === null || number === undefined) {
      return null;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  function getPredictionRounded(prediction) {
    return Math.round(prediction / 1000) * 1000;
  }

  const predictionRounded = getPredictionRounded(apartment.prediction)

  console.log(apartment);
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden w-full sm:w-80 p-4 border-solid border-2 flex flex-col justify-between">
    <div>
      <h3 className="text-xl font-semibold mb-2">{apartment.area}</h3>
      <div className="flex justify-between items-center">
        <p className="text-gray-600">{apartment.address}</p>
        <a href={`https://www.booli.se/annons/${apartment.booliId}`} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4" style={{ minHeight: "160px" }}>
        {apartment.built && (
          <div>
            <p className="text-sm font-semibold">Built</p>
            <p>{apartment.built}</p>
          </div>
        )}
        {apartment.floor && (
          <div>
            <p className="text-sm font-semibold">Floor</p>
            <p>{apartment.floor}</p>
          </div>
        )}
        {apartment.rooms && (
          <div>
            <p className="text-sm font-semibold">Rooms</p>
            <p>{apartment.rooms}</p>
          </div>
        )}
        {apartment.sqm && (
          <div>
            <p className="text-sm font-semibold">m&sup2;</p>
            <p>{apartment.sqm}</p>
          </div>
        )}
        {apartment.list_price && (
          <div>
            <p className="text-sm font-semibold">List Price</p>
            <p>{formatNumberWithWhitespace(apartment.list_price)} Kr</p>
          </div>
        )}
        {apartment.days_for_sale !== null && apartment.days_for_sale !== undefined && (
          <div>
            <p className="text-sm font-semibold">Days for Sale</p>
            <p>{apartment.days_for_sale}</p>
          </div>
        )}
        {apartment.rent && (
          <div>
            <p className="text-sm font-semibold">Rent</p>
            <p>{apartment.rent} Kr</p>
          </div>
        )}
        {apartment.prediction && (
          <div>
            <p className="text-sm font-semibold">Prediction</p>
            <p>{formatNumberWithWhitespace(predictionRounded)} Kr</p>
          </div>
        )}
      </div>
    </div>
    {loggedIn && (
      <div className="mt-10">
        {successMessage && <div className="bg-green-100 text-green-700 p-2 rounded mb-2">{successMessage}</div>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitPrediction();
          }}
        >
          <input
            className="border border-gray-300 rounded-md px-2 py-1 w-full"
            type="text"
            placeholder={placeholder}
            value={userPrediction}
            onChange={(e) => setUserPrediction(e.target.value)}
          />

          <button className="bg-blue-500 text-white rounded-md px-2 py-1 mt-2 w-full" type="submit">
            Submit Prediction
          </button>
        </form>
      </div>
    )}
  </div>
  );
  
};

export default ApartmentCard;
