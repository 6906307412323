import React from 'react';

const Header = ({logoPicture}) => (
  <div className="p-4 flex justify-center">  
    <div className="w-3/5 h-auto relative">  
      <img src={logoPicture} alt="Bjurfors Logo" className="m-auto" />  
    </div>
  </div>
);

export default Header;
