import React, { useState, useEffect, useCallback } from 'react';
import AddressInput from './AddressInput';
import axios from 'axios';
import apiBaseUrl from './apiConfig';


function LandingPage({ setFormData, nextStep }) {
  const [buttonValid, setButtonValid] = useState(false);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && buttonValid) {
      e.preventDefault();
      nextStep();
    }
  }, [buttonValid, nextStep]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  
  const handleAddressSelect = async (suggestion) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/location`, { id: suggestion.id });
      const { postcode, streetAddress } = response.data;
      setFormData(prevState => ({
        ...prevState,
        postcode,
        streetAddress
      }));
      setButtonValid(true);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
      <div 
        className="flex flex-col items-center mt-6 md:mt-0 w-4/5 sm:w-full mx-auto max-w-2xl md:justify-start"
        onKeyDown={handleKeyDown}
      >
        <div className='text-center text-2xl mt-2 w-full'>
          <h1>Okej! Nu värderar vi en bostad</h1>
        </div>
        <AddressInput onAddressSelect={handleAddressSelect} />
        <div className="flex justify-center mt-6 w-full">
          <button 
            className={`rounded-full p-2 w-2/5 mr-6 h-11 font-semibold ${buttonValid ? 'bg-black text-white' : 'bg-gray-400 text-gray-700 pointer-events-none'}`} 
            onClick={nextStep}
          >
            Värdera nu!
          </button>
        </div>
        <div className="flex justify-left mt-8 w-full">
          <ul className="list-disc list-inside space-y-3">
            <li>Du får svar direkt - färdig på en minut!</li>
            <li>Gratis - värdera en eller flera bostäder</li>
            <li>Sveriges vassaste AI-värderingsmodell</li>
          </ul>
        </div>
      </div>
    </>
  );

}

export default LandingPage;
