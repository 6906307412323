import React, { useState } from 'react';
import Header from './Header';
import LandingPage from './LandingPage';
import Sqm from './Sqm';
import Rent from './Rent';
import Rooms from './Rooms';
import Floor from './Floor';
import ConstructionYear from './ConstructionYear';
import Balcony from './Balcony';
import PersonDetails from './PersonDetails';
import FinalPage from './FinalPage';


const MultiStepForm = ({ divColor }) => {
  const [currentStep, setCurrentStep] = useState(1);
  
  const [formData, setFormData] = useState({
    rent: '',
    livingArea: '',
    rooms: '',
    floor: '',
    constructionYear: '',
    streetAddress: '',
    postcode: '',
    balcony: null,
  });

  const [prediction, setPrediction] = useState(null);
  

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const renderStep = () => {
    switch(currentStep) {
      case 1: return <LandingPage       setFormData={setFormData} formData={formData} nextStep={nextStep} />;
      case 2: return <Sqm               setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 3: return <Rent              setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 4: return <Rooms             setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 5: return <Floor             setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 6: return <ConstructionYear  setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 7: return <Balcony           setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} />;
      case 8: return <PersonDetails     setFormData={setFormData} formData={formData} nextStep={nextStep} prevStep={prevStep} setPrediction={setPrediction}/>;
      case 9: return <FinalPage         formData={formData} prediction={prediction}  divColor={divColor} />;
      default: return null;
    }
  };

  return (
    <div > 
      {renderStep()}
    </div>
  );
};

function Form({headerPicture, divColor}) {
  return (
    <div >
      <Header  logoPicture={headerPicture}/>
      <MultiStepForm divColor={divColor} />
    </div>
  );
}

export default Form;
