import React from 'react';
import brokerPic from './broker.PNG';
import { FcPhoneAndroid } from 'react-icons/fc';
import { TfiEmail } from 'react-icons/tfi';

function FinalPage({formData, prediction, divColor}) {
    const roundTenK = (num) => {
        return Math.round(num / 10000) * 10000;
    };
    const fivePercentAbove = (num) => {
        return Math.round(num * 1.05);
    };
    const fivePercentBelow = (num) => {
        return Math.round(num * 0.95);
    };
    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const formatedPrediction = formatNumber(roundTenK(prediction.prediction));
    const formatedPredictionFivePercentAbove = formatNumber(fivePercentAbove(roundTenK(prediction.prediction)));
    const formatedPredictionFivePercentBelow = formatNumber(fivePercentBelow(roundTenK(prediction.prediction)));

    return (
        <>
            <div className="flex flex-col items-center mt-6 md:mt-0 w-4/5 sm:w-full mx-auto max-w-2xl md:justify-start flex-grow">
                <div className="text-left w-4/5">
                    <p>Här är din värdering!</p>
                    <p className='mt-6'>{formData.streetAddress}</p>
                    <p>Lägenhet på {formData.livingArea} kvm med {formData.rooms} rum</p>

                    <p className='mt-10 font-bold text-4xl text-center'>~ {formatedPrediction} kr</p>
                    <p className='mt-4 text-base'>Ett spann mellan {formatedPredictionFivePercentBelow} kr och {formatedPredictionFivePercentAbove} kr</p>
                    <p className='mt-4 underline underline-offset-1'>Läs mer om hur värderingen går till</p>
                    <p className='mt-4'> Värderingen baseras på statistisk data och kan skilja sig från det faktiska värdet. Kontakta mäklaren här för en kostnadsfri värdering</p>
                </div>

                <div className={`w-4/5 ${divColor} flex flex-col items-center rounded-xl mt-4`}>
                    <img src={brokerPic} className='m-2 rounded-xl w-32 h-auto' alt='Broker pic'/>
                    <p className='text-white font-bold text-xl'>Förnamn Efternamn</p>
                    <p className='text-white text-l'>Fastighetsmäklare</p>
                    <div className='border-2 rounded-xl p-1 mt-2 flex items-center'>
                        <FcPhoneAndroid className='mr-2 text-xl'/>
                        <p className='text-white'>070 123 45 67</p>
                    </div>
                    <div className='border-2 rounded-xl p-1 mt-2 flex items-center mb-4'>
                        <TfiEmail className='mr-2 text-l' color='white'/>
                        <p className='text-white text-xs'>Förnamn.Efternamn@Mäklare.se</p>
                    </div>
                </div>

                

            </div>
        </>
    );
}

export default FinalPage;
