import React, { useState, createContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Helmet } from 'react-helmet';
import Navbar from './page/Navbar';
import Register from './page/Register';
import Login from './page/Login';
import Home from './page/Home';
import Find from './page/Find';
import Landing from './page/Landing';
import Ranking from './page/Ranking';
import Tracking from './page/Tracking';
import { SearchProvider } from './page/SearchContext';
import Form from './form/Form';
import genericLogo from './form/genericLogo.png';
import bjurforsLogo from './form/bjurfors-logo.jpg';

export const UserContext = createContext();

const DIVCOLORBJURFORS = "bg-customGray";
const DIVCOLORGENERIC = "bg-[#2B2B2B]";

function App() {

  function Layout() {
    const demoRegExp = /demo/i;
    const location = useLocation();
    return (
      <>
        { !demoRegExp.test(location.pathname) && <Navbar /> }
      </>
    );
  }

  const queryClient = new QueryClient();

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [userId, setUserId]     = useState(Number(localStorage.getItem('userId')) || 0);

  const handleLogin = (user) => {
    setUsername(user.username);
    setUserId(user.id);
    setLoggedIn(true);
    console.log('Logged in as:', user);

    localStorage.setItem('username', user.username);
    localStorage.setItem('userId', user.id);
    localStorage.setItem('loggedIn', true);
  };

  const handleLogout = () => {
    setUsername('');
    setUserId(0);
    setLoggedIn(false);
  
    console.log('Logged out');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('loggedIn');
  };

  return (
    <div>
       <Helmet>
        <title>HomeAI</title>
        <meta name="description" content="The most accurate apartment pricing in sthockholm inner city" />
      </Helmet>
      <UserContext.Provider value={{ loggedIn, username, userId, handleLogin, handleLogout }}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
          <Layout />
            <SearchProvider>
              <Routes>
                <Route path="/"                 element={<Navigate to="/landing" />} />
                <Route path="/landing"          element={<Landing />} />
                <Route path="/register"         element={<Register onLogin={handleLogin} />} />
                <Route path="/login"            element={<Login />} />
                <Route path="/home"             element={loggedIn ? <Home  onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
                <Route path="/find"             element={<Find />} />
                <Route path="/ranking"          element={<Ranking />} />
                <Route path="/tracking"         element={loggedIn ? <Tracking /> : <Login onLogin={handleLogin} />} />
                <Route path="/BjurforsDemo"     element={<Form  headerPicture={bjurforsLogo} divColor={DIVCOLORBJURFORS}/>} />
                <Route path="/demo"             element={<Form  headerPicture={genericLogo}  divColor={DIVCOLORGENERIC}/>} />
                <Route path="*"                 element={<Landing />} />
              </Routes>
            </SearchProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
